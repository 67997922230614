<template>
  <div class="disinfectDetails">
    <div class="deviceInfo">
      <div class="header">
        <div class="name">
          {{ deviceInfo.name }}
        </div>
        <el-rate
          disabled
          :disabled-void-color="'#7A86B6'"
          class="rate"
          v-model="3.7"
          :allow-half="true"
          text-color="#ff9900"
          score-template="{value}"
        >
        </el-rate>
        <div class="status">未消毒</div>
      </div>
      <div class="order">
        <span class="orderId">单号 {{ orderId }}</span
        ><span class="orderTime"
          >借用时间 {{ formatDate(deviceInfo.orderTime) }}</span
        >
      </div>
      <div class="content">
        <div class="item" v-for="item in keyList" :key="item.key">
          <span class="name">{{ item.name }}</span>
          <span class="key">{{ deviceInfo[item.key] || "-" }}</span>
        </div>
      </div>
    </div>
    <div class="uploadImgNav">
      <div class="title">上传照片</div>
      <div class="imageList">
        <div
          class="item"
          v-for="(item, index) in imageList"
          :key="index"
          @click="activeIdx = index"
        >
          <div class="main">
            <div class="name">{{ item.name }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
          <div class="uploadImg">
            <span class="imgDel" @click.stop="imgDel(index)" v-if="item.image">
              <i class="el-icon-close"></i>
            </span>

            <img
              :src="$replacePhoto(item.image)"
              alt=""
              v-if="item.image"
              @click="showImageList(index)"
            />
            <img v-else :src="detailsUploadBg" alt="" @click="doSomeThing" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div
        class="button"
        :class="{ btnDisable: photoId.length == 0 }"
        @click="showDialog(2)"
      >
        完成消毒
      </div>
      <div
        class="button"
        :class="{ btnDisable: !mayClean }"
        @click="showDialog(1)"
      >
        归还
      </div>
    </div>
    <van-image-preview
      v-model="showImage"
      :images="images"
      :startPosition="imagesIdx"
      :closeable="true"
    >
    </van-image-preview>
  </div>
</template>

<script>
export default {
  name: "disinfectDetails",
  data() {
    return {
      showImage: false,
      images: [],
      imagesIdx: 0,
      deviceInfo: {},
      mayClean: false,
      activeIdx: 0,
      detailsUploadBg: require("../../../../../static/iconImage/detailsUploadBg.png"),
      dialogType: "",
      keyList: [
        { key: "brandName", name: "品 牌" },
        { key: "code", name: this.$VUEAPPDEVICECODE() },
        { key: "label", name: "卡片号" },
        { key: "categoryName", name: "设备分类" },
        { key: "modelName", name: "型 号", space: "21px" },
      ],
      imageList: [
        {
          image: "",
          name: "正面照",
          text: "请上传仪器的正面照",
          id: "",
        },
        {
          image: "",
          name: "左侧照",
          text: "请上传仪器的左侧照",
          id: "",
        },
        {
          image: "",
          name: "右侧照",
          text: "请上传仪器的右侧照",
          id: "",
        },
        {
          image: "",
          name: "背面照",
          text: "请上传仪器的背面照",
          id: "",
        },
      ],
    };
  },
  computed: {
    photoId() {
      let _ids = [];
      this.imageList.forEach((item) => {
        if (item.id) {
          _ids.push(item.id);
        }
      });
      return _ids;
    },
  },
  created() {
    this.orderId = this.$route.query.orderId;
    this.shareCleaninfo();
  },
  beforeDestroy() {
    this.removeLocalStorageEvent(this.goBack)
  },
  mounted() {
    this.$nextTick(() => {
      // 配置微信jssdk
      if (typeof localStorage["corpInfo"] != "undefined") {
        try {
          let corpInfo = JSON.parse(localStorage["corpInfo"]);
          this.wxConfig(corpInfo);
        } catch (e) {}
      }
    });
    this.addLocalStorageEventByKey('goBack', this.goBack)
  },
  methods: {
    goBack:function() {
      setTimeout(() => {
        this.$back()
      }, 500)
    },
    shareCleaninfo() {
      this.$api.deviceBorrowModule
        .shareCleaninfo({
          id: this.orderId,
        })
        .then((res) => {
          this.deviceInfo = res.data;
          let _cleanPhotos = this.deviceInfo.cleanPhotos;
          if (_cleanPhotos && _cleanPhotos.length > 0) {
            _cleanPhotos.forEach((element, index) => {
              this.imageList[index].image = element.url.replace(
                "http://192.168.1.243:10000",
                window.ipConfig.VUE_APP_BASEURL
              );
              this.imageList[index].id = element.id;
            });
          }
        })
        .catch((e) => {});
    },
    showDialog(type) {
      if (type == 1 && this.mayClean) {
        this.toGiveBackDetails();
      } else if (type == 1 && !this.mayClean) {
        this.$toast.fail("请完成设备消毒");
      } else if (type == 2 && this.photoId.length >= 1) {
        this.$dialog.confirm({
          message: `确认设备完成消毒？`,
          beforeClose: this.shareClean,
        });
      } else if (type == 2 && this.photoId.length == 0) {
        this.$toast.fail("请完成设备图片拍摄");
      }
    },
    doSomeThing() {
      this.chooseImage().then((res) => {
        if (res.result == false) {
          this.imageId = "";
          this.$toast.fail("请重新选择图片");
          return;
        }
        this.imageId = res.localID;
        this.saveImageToServer();
      });
    },
    saveImageToServer() {
      if (typeof localStorage["corpInfo"] != "undefined") {
        try {
          let corpInfo = JSON.parse(localStorage["corpInfo"]);
          this.$showLoading({
            target: ".disinfectDetails",
            text: "正在获取图片...",
          });
          this.uploadImageToWeChatAndGet(this.imageId, corpInfo)
            .then((res) => {
              if (res.result == false) {
                this.$toast.fail("获取图片失败");
                this.loading = false;
                return;
              }
              this.imageList[this.activeIdx].image =
                res.image.url.replace(
                  "http://192.168.1.243:10000",
                  window.ipConfig.VUE_APP_BASEURL
                ) + "&scala=0.3";
              this.imageList[this.activeIdx].id = res.image.id;
              this.$hideLoading({
                target: ".disinfectDetails",
              });
            })
            .catch((err) => {
              this.$hideLoading({
                target: ".disinfectDetails",
              });
            });
        } catch (e) {
          this.$toast.fail("解析图片失败");
          this.loading = false;
        }
      }
    },
    showImageList: function (index) {
      this.images = [];
      this.imagesIdx = index;
      for (let i = 0; i < this.imageList.length; i++) {
        let data = this.imageList[i];
        this.images.push(this.$replacePhoto(data.image));
      }
      this.showImage = true;
    },
    toGiveBackDetails() {
      this.$push("deviceBorrow/giveBackDetails", {
        id: this.orderId,
      });
    },
    shareClean(action, done) {
      if (action === "confirm") {
        this.$api.deviceBorrowModule
          .shareClean({
            id: this.orderId,
            photoId: this.photoId.join(),
          })
          .then((res) => {
            localStorage.setItem("allDeviceUpdate", new Date().getTime());
            this.$toast.success("完成消毒");
            this.mayClean = true;
            done();
          })
          .catch((e) => {
            done();
            this.$toast.success(e.data.msg);
          });
      } else {
        done();
      }
    },
    imgDel(index) {
      this.imageList[index].image = "";
      this.imageList[index].id = "";
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.disinfectDetails {
  height: 100%;
  overflow: scroll;
  .deviceInfo {
    background: #3e73fb;
    color: #fff;
    padding-bottom: 32px;
    .header {
      display: flex;
      padding: 12px 18px 12px 21px;
      line-height: 24px;
      .name {
        flex: 1;
        font-size: 16px;
        font-weight: 500;
      }
      .rate {
        width: 84px;
      }
      .status {
        width: 56px;
        text-align: center;
        background: #00e78c;
        border-radius: 4px;
      }
    }
    .order {
      padding: 0 18px 0 21px;
      .orderId,
      .orderTime {
        display: inline-block;
        width: 50%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    .content {
      padding: 0 24px;
      font-size: 12px;
      .item {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
      }
    }
  }
  .uploadImgNav {
    background: $background_color2;
    border-radius: 20px 20px 0px 0px;
    padding-top: 20px;
    margin-top: -20px;
    overflow: hidden;
    .title {
      padding-left: 26px;
      font-size: 16px;
      font-weight: 500;
      color: $color1;
    }
    .imageList {
      .item {
        padding: 6px 16px 6px 24px;
        box-shadow: 0px 0px 4px 0px rgba(104, 104, 104, 0.12);
        border-radius: 8px;
        margin: 10px 16px;
        display: flex;
        justify-content: space-between;
        .main {
          padding-top: 6px;
          .text {
            font-size: 12px;
            color: $color3;
          }
        }
        .uploadImg {
          width: 156px;
          height: 104px;
          overflow: hidden;
          border-radius: 4px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .imgDel {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            position: absolute;
            background: rgba($color: #323233, $alpha: 0.2);
            top: 4px;
            right: 4px;
            color: #fff;
            font-weight: bold;
            i {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      .itemActive {
        background: $sub_color_primary;
      }
    }
  }
  .footer {
    padding: 14px 10px 40px;
    display: flex;
    justify-content: space-between;
    background: $background_color2;
    .button {
      width: 140px;
      line-height: 40px;
      height: 40px;
      text-align: center;
      border-radius: 20px;
      padding: 0;
      font-size: 12px;
      background: #3e73fb;
      color: #fff;
      &.btnDisable {
        background: #909399;
        border: 0;
      }
    }
  }
}
::v-deep .header .el-rate__item {
  width: 12px;
  vertical-align: sub;
}

::v-deep .header .el-rate__icon {
  font-size: 12px;
}

::v-deep .header .el-rate__text {
  margin-left: 3px;
}
::v-deep .van-image {
  scale: 0.8;
}
</style>
